import { createStore } from 'vuex'

export default createStore({
  state: {
    gitHubLogin: null,
    gitHubConnectedRepository: null,
    adminManifest: null
  },
  getters: {
  },
  mutations: {
    initFromLocalStorage(state) {
      const gitHubLogin = localStorage.getItem('gitHubLogin');
      if (gitHubLogin !== null) state.gitHubLogin = JSON.parse(gitHubLogin);
      
      const gitHubConnectedRepository = localStorage.getItem('gitHubConnectedRepository');
      if (gitHubConnectedRepository !== null) state.gitHubConnectedRepository = JSON.parse(gitHubConnectedRepository);
    },
    setGitHubLogin(state, value) {
      if (value === null) localStorage.removeItem('gitHubLogin');
      else localStorage.setItem('gitHubLogin', JSON.stringify(value));
      state.gitHubLogin = value;
    },
    setGitHubConnectedRepository(state, value) {
      if (value === null) localStorage.removeItem('gitHubConnectedRepository');
      else localStorage.setItem('gitHubConnectedRepository', JSON.stringify(value));
      state.gitHubConnectedRepository = value;
    },
    setAdminManifest(state, value) {
      state.adminManifest = value;
    }
  },
  actions: {
    disconnectGitHub({ commit }) {
      commit('setGitHubLogin', null);
      commit('setGitHubConnectedRepository', null);
    }
  },
  modules: {
  }
})
