import { createApp } from 'vue/dist/vue.esm-bundler' // Vue (https://vuejs.org)
import App from './App.vue'
import router from './router' // Vue Router (https://router.vuejs.org)
import store from './store' // Vuex (https://vuex.vuejs.org)
import { createMetaManager } from 'vue-meta' // Vue Meta (https://vue-meta.nuxtjs.org)

// FontAwesome (CC BY 4.0)
import { library } from '@fortawesome/fontawesome-svg-core'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { faUser, faKey, faCheck, faInfo, faListOl, faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faGithub, faUser, faKey, faCheck, faInfo, faListOl, faPenToSquare, faTrashCan)

// highlight.js
import 'highlight.js/styles/stackoverflow-light.css'
import 'highlight.js/lib/common'
import hljsVuePlugin from "@highlightjs/vue-plugin"

import RenderedElementCode from '@/components/rendered/RenderedElementCode.vue'
import RenderedElementImage from '@/components/rendered/RenderedElementImage.vue'

// Vue app setup
createApp(App)
    .use(store)
    .use(router)
    .use(createMetaManager())
    .use(hljsVuePlugin)
    .component('font-awesome-icon', FontAwesomeIcon)
    .component('RenderedElementCode', RenderedElementCode)
    .component('RenderedElementImage', RenderedElementImage)
    .mount('#app')
