<template>
  <metainfo> <!-- Vue Meta injection -->
    <template v-slot:title="{ content }">{{ content }}</template>
  </metainfo>
  <router-view />
</template>

<script>
export default {
  beforeCreate() {
    this.$store.commit('initFromLocalStorage');
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/styles";

html {
  height: 100%;
}
</style>
