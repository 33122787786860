import { createRouter, createWebHistory } from 'vue-router'

/*
webpack chunk names:
- view:  For viewing published book only.
- admin: For administrating/writing book.
*/

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "view" */ '../views/Home.vue'),
    children: [
      {
        path: '',
        name: 'ViewHome',
        component: () => import(/* webpackChunkName: "view" */ '../views/ViewHome.vue')
      },
      {
        path: ':slug',
        name: 'ViewChapter',
        component: () => import(/* webpackChunkName: "view" */ '../views/ViewChapter.vue')
      },
    ]
  },
  {
    path: '/-',
    name: 'AdminHome',
    component: () => import(/* webpackChunkName: "admin" */ '../views/AdminHome.vue'),
    redirect: { name: 'EditHome' },
    children: [
      {
        path: 'connect',
        name: 'Connect',
        component: () => import(/* webpackChunkName: "admin" */ '../views/Connect.vue')
      },
      {
        path: 'edit',
        name: 'Edit',
        component: () => import(/* webpackChunkName: "admin" */ '../views/Edit.vue'),
        children: [
          {
            path: '',
            name: 'EditHome',
            component: () => import(/* webpackChunkName: "admin" */ '../views/EditHome.vue')
          },
          {
            path: 'manifest',
            name: 'EditManifest',
            component: () => import(/* webpackChunkName: "admin" */ '../views/EditManifest.vue')
          },
          {
            path: 'chapter/:id',
            name: 'EditChapter',
            component: () => import(/* webpackChunkName: "admin" */ '../views/EditChapter.vue')
          },
        ]
      },
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404NotFound',
    component: () => import(/* webpackChunkName: "admin" */ '../views/404NotFound.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
