<template>
  <div v-if="isAdminPreview" class="image-preview">Image will be loaded from '{{ href }}' when published.</div>
  <img v-else :src="href" :alt="text" :title="title">
</template>

<script>
export default {
  name: 'RenderedElementImage',
  components: {
  },
  props: {
    href: String,
    title: String,
    text: String,
    isAdminPreview: Boolean
  },
  data() {
    return { }
  },
}
</script>

<style lang="scss" scoped>
.image-preview {
  display: inline;
  background-color: $light;
  border: 1px solid $dark;
  padding: 0.2em 0.4em;
}
</style>
