<template>
  <highlightjs v-if="isAdminPreview" language="shell" :code="previewCode" />
  <highlightjs v-else-if="code !== null" autodetect :code="code" />
</template>

<script>
import WebFileService from '@/assets/js/WebFileService.js'

export default {
  name: 'RenderedElementCode',
  components: {
  },
  props: {
    src: String,
    isAdminPreview: Boolean
  },
  data() {
    return {
      code: null,
      previewCode: ""
    }
  },
  created() {
    if (this.isAdminPreview) {
      this.previewCode = `Code will be loaded from '${this.src}' when published.`;
    } else {
      const wf = WebFileService.getInstance();
      this.code = wf.loadFile(this.src);
    }
  }
}
</script>

<style lang="scss">
.content pre {
  display: grid;

  code {
    max-height: 500px;
    overflow-y: auto;
  }
}
</style>
