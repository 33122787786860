export default class WebFileService {
  static #instance;
  #fileCache = {};

  constructor() {
  }

  /**
   * Obtain the singleton instance of this service.
   *
   * @static
   * @return {WebFileService} the singleton instance
   * @memberof WebFileService
   */
  static getInstance() {
    if (!WebFileService.#instance) {
      const service = new WebFileService();
      WebFileService.#instance = service;
    }

    return WebFileService.#instance;
  }

  /**
   * Load a text file from a URL.
   *
   * @param {string} filePath the path to the file
   * @return {?string} the contents of the file, or null on failure
   * @memberof WebFileService
   */
  loadFile(filePath) {
    const cachedFile = this.#fileCache[filePath];
    if (cachedFile) {
      return cachedFile;
    }

    let result = null;
    const xmlhttp = new XMLHttpRequest();
    xmlhttp.open("GET", filePath, false);
    xmlhttp.send();
    
    if (xmlhttp.status === 200) {
      result = xmlhttp.responseText;
      if (result.includes('data-mobooks-index="1"')) {
        // The file does not exist.
        // The request has been rewritten to the index.html file to support SPA routing.
        // Treat this as a 404.
        return null;
      }
      this.#fileCache[filePath] = result;
    }
    return result;
  }

  /**
   * Load the book manifest.json file.
   *
   * @return {?object} the manifest object, or null if it does not exist
   */
  loadManifest() {
    const manifestFilePath = '/booksrc/manifest.json';
    const fileContent = this.loadFile(manifestFilePath);

    return fileContent === null ? null : JSON.parse(fileContent);
  }
}
